@tailwind base;
@tailwind components;
@tailwind utilities;

/* Existing styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.standard-image {
  width: 100%;        /* Make sure it takes full container width */
  max-width: 400px;   /* Adjust as needed for your design */
  height: auto;       /* Maintain aspect ratio */
  object-fit: cover;  /* Crop edges if needed to fit the container */
  border-radius: 10px; /* Add rounded edges for a cleaner look */
}
.thumbnail-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px; /* Adjust to add a consistent look */
}
